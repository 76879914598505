import axios from 'axios';
import Qs from 'qs';
import apiAll from './api';

let base = 'http://fnji.com';
let baseOms81 = 'http://omsv2.fnji.com:8081';
let baseOms = 'http://omsv2.fnji.com:8080';
let baseOms1 = 'http://oasrv.fnji.com:8081';
let baseOms2 = 'http://oasrv.fnji.com:8082';
let duola = 'http://test-pdt.dotax.cn/pdt-api';

let gpt = 'http://aimatehub.com:8082/mt/s';
let gptHttps = 'https://aimatehub.com/mt/s';

let config = {
    transformRequest: [function (data) {
        return Qs.stringify(data)
    }],
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}
let importFile = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}
let duolaConfig = {
    transformRequest: [function (data) {
        return Qs.stringify(data)
    }],
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'AppSecret': 'fJRSN5GfXJ$vqv',
        'TenantId': '297'
    }
}
//GPT
export const qaStreamApi = params => { return axios.post(`${gpt}/GptChatModel/qaStream`, params,config).then(res => res.data); };//gpt发送消息

export const getTempUserIdApi = params => { return axios.post(`${gptHttps}/gptUser/getTempUserId`, params,config).then(res => res.data); };//gpt获取临时ID
export const getAIQSApi = params => { return axios.post(`${gptHttps}/GptChatModel/getAIQS`, params,config).then(res => res.data); };//gpt获取问题数组
export const verifyCodeApi = params => { return axios.post(`${gptHttps}/gptUser/verifyCode`, params,config).then(res => res.data); };//gpt获取验证码
export const loginByCodeApi = params => { return axios.post(`${gptHttps}/gptUser/loginByCode`, params,config).then(res => res.data); };//gpt登录
export const gptPayApi = params => { return axios.post(`${gptHttps}/PayStripModel/pay`, params,config).then(res => res.data); };//gpt付款链接
export const queryApi = params => { return axios.post(`${gptHttps}/PayStripModel/query`, params,config).then(res => res.data); };//gpt查询付款记录
export const queryGradeApi = params => { return axios.post(`${gptHttps}/PayStripModel/queryGrade`, params,config).then(res => res.data); };//gpt查询用户等级
export const unsubscribeApi = params => { return axios.post(`${gptHttps}/PayStripModel/unsubscribe`, params,config).then(res => res.data); };//gpt取消订阅
export const saveGptHistoryApi = params => { return axios.post(`${gptHttps}/gptUser/saveHistory`, params,config).then(res => res.data); };//gpt保存历史记录
export const getGptHistoryApi = params => { return axios.post(`${gptHttps}/gptUser/queryHistory`, params,config).then(res => res.data); };//gpt获取历史记录
export const mjCreateApi = params => { return axios.post(`${gptHttps}/AIMjModel/mjCreate`, params,config).then(res => res.data); };//gpt创建图片
export const mjRefreshApi = params => { return axios.post(`${gptHttps}/AIMjModel/mjRefresh`, params,config).then(res => res.data); };//gpt生成图片
export const mjBigApi = params => { return axios.post(`${gptHttps}/AIMjModel/mjBig`, params,config).then(res => res.data); };//gpt根据图片生成图片
export const getAllByDateApi = params => { return axios.post(`${gptHttps}/AIMjModel/getAllByDate`, params,config).then(res => res.data); };//gpt获取图片历史记录



export const getSpuList = params => { return axios.post(`${base}/m/s/SupplyerBoxDefEntity/getListPage`, params,config).then(res => res.data); };//SPU产品列表
export const getSpuApi = params => { return axios.post(`${base}/m/s/SupplyerBoxDefEntity/get`, params,config).then(res => res.data); };//查询SPU产品
export const modSpuApi = params => { return axios.post(`${base}/m/s/SupplyerBoxDefEntity/mod`, params,config).then(res => res.data); };//修改SPU产品

export const getPurchaseList = params => { return axios.post(`${base}/m/s/SupplyerOrderEntity/getListPage`, params,config).then(res => res.data); };//采购订单列表
export const getPurchaseApi = params => { return axios.post(`${base}/m/s/SupplyerOrderEntity/get`, params,config).then(res => res.data); };//查询采购订单

export const getBasicApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInEntity/getBasic`, params,config).then(res => res.data); };//采购订单点击发货
export const addBasicApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInEntity/add`, params,config).then(res => res.data); };//采购订单(添加送货单)

export const getInStockEnumList = params => { return axios.post(`${base}/m/s/SupplyerOrderInEntity/getInStockEnum`, params,config).then(res => res.data); };//收货仓库列表
export const getDeliveryList = params => { return axios.post(`${base}/m/s/SupplyerOrderInEntity/getListPage`, params,config).then(res => res.data); };//送货单列表
export const getDeliveryApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInEntity/get`, params,config).then(res => res.data); };//查询送货单
export const modStatusApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInEntity/modStatus`, params,config).then(res => res.data); };//送货单(提交/驳回)
export const modDeliveryApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInEntity/mod`, params,config).then(res => res.data); };//送货单(保存)
export const delDeliveryApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInEntity/delBatch`, params,config).then(res => res.data); };//删除送货单

export const getCategoryTree = params => { return axios.post(`${base}/m/s/ErpProductTypeEntity/getCategoryTree`, params,config).then(res => res.data); };//产品分类树
export const getProductList = params => { return axios.post(`${base}/m/s/stock-raw/getListPageQ`, params,config).then(res => res.data); };//产品列表

export const getCodeList = params => { return axios.post(`${base}/m/s/SupplyerBomCodeEntity/getListPage`, params,config).then(res => res.data); };//条码列表
export const getOrderList = params => { return axios.post(`${base}/m/s/SupplyerOrderStaticEntity/getListPage`, params,config).then(res => res.data); };//采购订单列表
export const repairListBySupplier = params => { return axios.post(`${base}/m/s/RepairGoodsEntity/repairListBySupplier`, params,config).then(res => res.data); };//供应商订单列表
export const exportEList = params => { return axios.post(`${base}/m/s/SupplyerOrderStaticEntity/exportE`, params,config).then(res => res.data); };//采购订单导出
export const exportRepairList = params => { return axios.post(`${base}/m/s/RepairGoodsEntity/exportRepairList`, params,config).then(res => res.data); };//供应商订单导出

export const getDeliveryOtherList = params => { return axios.post(`${base}/m/s/SupplyerOrderInWEntity/getListPage`, params,config).then(res => res.data); };//送货单列表(三河)
export const getDeliveryOtherApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInWEntity/get`, params,config).then(res => res.data); };//查询送货单(三河)
export const addDeliveryOtherApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInWEntity/add`, params,config).then(res => res.data); };//添加送货单(三河)
export const modDeliveryOtherApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInWEntity/mod`, params,config).then(res => res.data); };//修改送货单(三河)
export const modDeliveryOtherStatusApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInWEntity/modStatus`, params,config).then(res => res.data); };//提交/驳回送货单(三河)
export const delDeliveryOtherStatusApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInWEntity/delBatch`, params,config).then(res => res.data); };//删除送货单(三河)

export const getSnApi = params => { return axios.post(`${base}/m/s/common/sn`, params,config).then(res => res.data); };//获取送货单号

export const repairOrderList = params => { return axios.post(`${base}/m/s/RepairGoodsEntity/repairOrderList`, params,config).then(res => res.data); };//返修单-列表|返修送货单-列表
export const getRepairOrderDetailApi = params => { return axios.post(`${base}/m/s/RepairGoodsEntity/getRepairOrderDetail`, params,config).then(res => res.data); };//商品列表详情（添加）
export const repairOrderDetailApi = params => { return axios.post(`${base}/m/s/RepairGoodsEntity/repairOrderDetail`, params,config).then(res => res.data); };//返修送货单|返修单- 详情
export const createDeliverRepairOrderApi = params => { return axios.post(`${base}/m/s/RepairGoodsEntity/createDeliverRepairOrder`, params,config).then(res => res.data); };//返修送货单-提交|保存
export const removeRepairOrderApi = params => { return axios.post(`${base}/m/s/RepairGoodsEntity/removeRepairOrder`, params,config).then(res => res.data); };//返修送货单-删除
export const delRepairOrderApi = params => { return axios.post(`${base}/m/s/RepairGoodsEntity/delRepairOrder`, params,config).then(res => res.data); };//返修送货单-驳回
export const modStatusRepairOrderApi = params => { return axios.post(`${base}/m/s/RepairGoodsEntity/modStatus`, params,config).then(res => res.data); };//返修送货单-发运

export const qaPersonApi = params => { return axios.post(`${base}/m/s/common/enum`, params,config).then(res => res.data); };//质检员列表

export const getGradeByDateApi = params => { return axios.post(`${base}/m/s/SupplierGradeEntity/getGradeByDate`, params,config).then(res => res.data); };//供应商考核
export const getGradeNameApi = params => { return axios.post(`${base}/m/s/SupplierGradeEntity/getGradeName`, params,config).then(res => res.data); };//获取考核数据文件夹

export const getListPageQApi = params => { return axios.post(`${base}/m/s/BomCodeApplyEntity/getListPageQ`, params,config).then(res => res.data); };//条码申请列表
export const getQSNApi = params => { return axios.post(`${base}/m/s/common/sn`, params,config).then(res => res.data); };//条码SN获取
export const getSupplierListApi = params => { return axios.post(`${base}/m/s/supplier/enum`, params,config).then(res => res.data); };//供应商列表
export const getStockListApi = params => { return axios.post(`${base}/m/s/stock-type/enum`, params,config).then(res => res.data); };//仓库列表
export const getBomCodeApplyEntityApi = params => { return axios.post(`${base}/m/s/BomCodeApplyEntity/get`, params,config).then(res => res.data); };//条码申请表单
export const getCategoryTreeApi = params => { return axios.post(`${base}/m/s/ErpProductTypeEntity/getCategoryTree`, params,config).then(res => res.data); };//商品树
export const getStockRawApi = params => { return axios.post(`${base}/m/s/stock-raw/getListPageQ`, params,config).then(res => res.data); };//商品列表
export const getBySupplyerApi = params => { return axios.post(`${base}/m/s/SupplyerUserEntity/getBySupplyer`, params,config).then(res => res.data); };//条码申请中间值
export const getAddBomCodeApplyApi = params => { return axios.post(`${base}/m/s/BomCodeApplyEntity/add`, params,config).then(res => res.data); };//条码申请(添加)
export const getDelBatchBomCodeApplyApi = params => { return axios.post(`${base}/m/s/BomCodeApplyEntity/delBatch`, params,config).then(res => res.data); };//条码申请(删除)
export const getModStatusBomCodeApplyApi = params => { return axios.post(`${base}/m/s/BomCodeApplyEntity/modStatus`, params,config).then(res => res.data); };//条码申请(提交:toStatus:1;驳回:toStatus:0)

export const getBindRfId2BomCodeListApi = params => { return axios.post(`${base}/m/s/RfIDEntity/getListPage`, params,config).then(res => res.data); };//RFID绑定列表
export const exportByDateRfIdApi = params => { return axios.post(`${base}/m/s/RfIDEntity/exportByDate`, params,config).then(res => res.data); };//RFID绑定列表导出

export const getInStockEnumApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInNotifyEntity/getInStockEnum`, params,config).then(res => res.data); };//交货通知单仓库枚举值
export const getNotifyListApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInNotifyEntity/getListPage`, params,config).then(res => res.data); };//交货通知单列表
export const exportFileNotifyListApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInNotifyEntity/exportFile`, params,config).then(res => res.data); };//导出交货通知单列表
export const notifyDelBatchApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInNotifyEntity/delBatch`, params,config).then(res => res.data); };//删除交货通知单
export const getNotifyInfoApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInNotifyEntity/get`, params,config).then(res => res.data); };//交货通知单(获取)
export const addNotifyInfoApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInNotifyEntity/add`, params,config).then(res => res.data); };//交货通知单(添加)
export const modNotifyInfoApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInNotifyEntity/mod`, params,config).then(res => res.data); };//交货通知单(修改)
export const modStatusNotifyInfoApi = params => { return axios.post(`${base}/m/s/SupplyerOrderInNotifyEntity/modStatus`, params,config).then(res => res.data); };//交货通知单(提交/驳回)
export const getPoProductListApi = params => { return axios.post(`${base}/m/s/SupplyerOrderStaticEntity/getListPage`, params,config).then(res => res.data); };//查询未交货PO商品列表

export const getBindChexkListApi = params => { return axios.post(`${base}/m/s/RfIDPrintEntity/getListPage`, params,config).then(res => res.data); };//条码补打列表
export const checkItemRfidApi = params => { return axios.post(`${base}/m/s/RfIDPrintEntity/checkItem`, params,config).then(res => res.data); };//通过条码/rfid查询绑定信息
export const addItemRfidApi = params => { return axios.post(`${base}/m/s/RfIDPrintEntity/addItem`, params,config).then(res => res.data); };//条码补打(新建)
export const unBindRfId2BomCodeApi = params => { return axios.post(`${base}/m/s/RfIDPrintEntity/unBindRfId2BomCode`, params,config).then(res => res.data); };//条码补打(解绑)

//预生产
export const supplyerListApi = params => { return axios.post(`${baseOms}/mt/s/ErpSupplyerQuery/getListPageQ`, params,config).then(res => res.data); };//供应商列表
export const bomListApi = params => { return axios.post(`${baseOms}/mt/s/ErpItemQuery/getListPageQ`, params,config).then(res => res.data); };//存货数据

export const preproListApi = params => { return axios.post(`${baseOms}/mt/s/PurchaseOrderPre/getListPageQ`, params,config).then(res => res.data); };//预生产(list)
export const getPreproApi = params => { return axios.post(`${baseOms}/mt/s/PurchaseOrderPre/get`, params,config).then(res => res.data); };//预生产(get)
export const addPreproApi = params => { return axios.post(`${baseOms}/mt/s/PurchaseOrderPre/add`, params,config).then(res => res.data); };//预生产(add)
export const modPreproApi = params => { return axios.post(`${baseOms}/mt/s/PurchaseOrderPre/mod`, params,config).then(res => res.data); };//预生产(mod)
export const delPreproApi = params => { return axios.post(`${baseOms}/mt/s/PurchaseOrderPre/del`, params,config).then(res => res.data); };//预生产(del)
export const delBatchPreproApi = params => { return axios.post(`${baseOms}/mt/s/PurchaseOrderPre/delBatch`, params,config).then(res => res.data); };//预生产(delBatch)
export const provePreproApi = params => { return axios.post(`${baseOms}/mt/s/PurchaseOrderPre/prove`, params,config).then(res => res.data); };//预生产(prove)
export const unProvePreproApi = params => { return axios.post(`${baseOms}/mt/s/PurchaseOrderPre/unProve`, params,config).then(res => res.data); };//预生产(unProve)

export const PPStockInListApi = params => { return axios.post(`${baseOms}/mt/s/PPStockIn/getListPageQ`, params,config).then(res => res.data); };//预生产入库(list)
export const getPPStockInApi = params => { return axios.post(`${baseOms}/mt/s/PPStockIn/get`, params,config).then(res => res.data); };//预生产入库(get)
export const addPPStockInApi = params => { return axios.post(`${baseOms}/mt/s/PPStockIn/add`, params,config).then(res => res.data); };//预生产入库(add)
export const modPPStockInApi = params => { return axios.post(`${baseOms}/mt/s/PPStockIn/mod`, params,config).then(res => res.data); };//预生产入库(mod)
export const delPPStockInApi = params => { return axios.post(`${baseOms}/mt/s/PPStockIn/del`, params,config).then(res => res.data); };//预生产入库(del)
export const delBatchPPStockInApi = params => { return axios.post(`${baseOms}/mt/s/PPStockIn/delBatch`, params,config).then(res => res.data); };//预生产入库(delBatch)
export const provePPStockInApi = params => { return axios.post(`${baseOms}/mt/s/PPStockIn/prove`, params,config).then(res => res.data); };//预生产入库(prove)
export const unProvePPStockInApi = params => { return axios.post(`${baseOms}/mt/s/PPStockIn/unProve`, params,config).then(res => res.data); };//预生产入库(unProve)

export const PPStockCheckListApi = params => { return axios.post(`${baseOms}/mt/s/PPStockCheck/getListPageQ`, params,config).then(res => res.data); };//库存调整(list)
export const getPPStockCheckApi = params => { return axios.post(`${baseOms}/mt/s/PPStockCheck/get`, params,config).then(res => res.data); };//库存调整(get)
export const addPPStockCheckApi = params => { return axios.post(`${baseOms}/mt/s/PPStockCheck/add`, params,config).then(res => res.data); };//库存调整(add)
export const modPPStockCheckApi = params => { return axios.post(`${baseOms}/mt/s/PPStockCheck/mod`, params,config).then(res => res.data); };//库存调整(mod)
export const delPPStockCheckApi = params => { return axios.post(`${baseOms}/mt/s/PPStockCheck/del`, params,config).then(res => res.data); };//库存调整(del)
export const delBatchPPStockCheckApi = params => { return axios.post(`${baseOms}/mt/s/PPStockCheck/delBatch`, params,config).then(res => res.data); };//库存调整(delBatch)
export const provePPStockCheckApi = params => { return axios.post(`${baseOms}/mt/s/PPStockCheck/prove`, params,config).then(res => res.data); };//库存调整(prove)
export const unProvePPStockCheckApi = params => { return axios.post(`${baseOms}/mt/s/PPStockCheck/unProve`, params,config).then(res => res.data); };//库存调整(unProve)

export const getPPStockListApi = params => { return axios.post(`${baseOms}/mt/s/PPStock/getListPageQ`, params,config).then(res => res.data); };//库存监控
export const getPPStockChangeListApi = params => { return axios.post(`${baseOms}/mt/s/PPStockChange/getListPageQ`, params,config).then(res => res.data); };//变更记录


export const getMenuTreeApi = params => { return axios.post(`${baseOms1}/mt/s/UbiggerMenuTreeModel/getMenuTree`, params,config).then(res => res.data); };//左树菜单
export const getUserAuthDepartEnumApi = params => { return axios.post(`${base}/m/s/admRoleUser/getUserAuthDepartEnum`, params,config).then(res => res.data); };//登陆枚举值
export const loginApi = params => { return axios.post(`${base}/m/s/admRoleUser/login`, params,config).then(res => res.data); };//登陆
export const getDepartTreeApi = params => { return axios.post(`${base}/m/s/admRoleUser/getDepartment`, params,config).then(res => res.data); };//部门树
export const getUserListApi = params => { return axios.post(`${base}/m/s/admRoleUser/getListPage`, params,config).then(res => res.data); };//员工列表
export const getUserInfoApi = params => { return axios.post(`${base}/m/s/admRoleUser/get`, params,config).then(res => res.data); };//员工详情
export const addUserApi = params => { return axios.post(`${base}/m/s/admRoleUser/add`, params,config).then(res => res.data); };//添加员工
export const modUserApi = params => { return axios.post(`${base}/m/s/admRoleUser/mod`, params,config).then(res => res.data); };//修改员工
export const delBatchUserApi = params => { return axios.post(`${base}/m/s/admRoleUser/delBatch`, params,config).then(res => res.data); };//删除员工
export const getRoleListApi = params => { return axios.post(`${base}/m/s/admRole/getListPage`, params,config).then(res => res.data); };//角色列表
export const getHomePageApi = params => { return axios.post(`${baseOms1}/mt/s/UbiggerWorkFlowRunV2Model/getListPageQ`, params,config).then(res => res.data); };//首页列表
export const getknowledgeBaseApi = params => { return axios.post(`${baseOms1}/mt/s/UbiggerKMModel/getListPageQ`, params,config).then(res => res.data); };//知识库

//开票接口
export const addInvoiceTableApi = params => { return axios.post(`${baseOms2}/mt/s/UbiggerTableSchemeV2Model/add`, params,config).then(res => res.data); };//新增发票表格
export const modInvoiceTableApi = params => { return axios.post(`${baseOms2}/mt/s/UbiggerTableSchemeV2Model/mod`, params,config).then(res => res.data); };//修改发票表格
export const delInvoiceTableApi = params => { return axios.post(`${baseOms2}/mt/s/UbiggerTableSchemeV2Model/del`, params,config).then(res => res.data); };//删除发票表格
export const getInvoiceTableApi = params => { return axios.post(`${baseOms2}/mt/s/UbiggerTableSchemeV2Model/getByUserId`, params,config).then(res => res.data); };//获取发票表格列表
// export const getInvoiceListApi = params => { return axios.post(`${baseOms1}/m/s/invoicing/getList`, params,config).then(res => res.data); };//开票列表
export const getInvoiceListApi = params => { return axios.post(`${baseOms2}/mt/s/SiteInvoiceV2Model/getListPageQ`, params,config).then(res => res.data); };//开票列表(新)
// export const getInvoiceInfoApi = params => { return axios.post(`${baseOms1}/m/s/invoicing/getinfo`, params,config).then(res => res.data); };//开票详情
export const getInvoiceInfoApi = params => { return axios.post(`${baseOms2}/mt/s/SiteInvoiceV2Model/get`, params,config).then(res => res.data); };//开票详情（新）
export const getDuolaToredApi = params => { return axios.post(`${baseOms2}/m/s/invoicing/duolaTored`, params,config).then(res => res.data); };//发票冲红
export const getDuolaRevokeApi = params => { return axios.post(`${baseOms2}/m/s/invoicing/duolaRevoke`, params,config).then(res => res.data); };//发票作废
export const getduolaSearchApi = params => { return axios.post(`${baseOms1}/m/s/invoicing/duolaSearch`, params,config).then(res => res.data); };//发票抬头列表
export const getOrderConListApi = params => { return axios.post(`${baseOms1}/m/s/invoicing/orderConList`, params,config).then(res => res.data); };//订单详情列表 
// export const submitInvoiceApi = params => { return axios.post(`${baseOms1}/m/s/invoicing/submitInvoice`, params,config).then(res => res.data); };//财务开票
export const submitInvoiceApi = params => { return axios.post(`${baseOms2}/mt/s/SiteInvoiceV2Model/add`, params,config).then(res => res.data); };//财务开票（新）
export const modInvoiceApi = params => { return axios.post(`${baseOms2}/mt/s/SiteInvoiceV2Model/mod`, params,config).then(res => res.data); };//财务开票修改（新）
export const getWorkFlowApi = params => { return axios.post(`${baseOms1}/mt/s/UbiggerWorkFlowV2Model/getWorkFlow`, params,config).then(res => res.data); };//开票审批状态查询
export const proveInvoiceApi = params => { return axios.post(`${baseOms2}/mt/s/UbiggerWorkFlowV2Model/prove`, params,config).then(res => res.data); };//开票审批流
export const unProveInvoiceApi = params => { return axios.post(`${baseOms2}/mt/s/UbiggerWorkFlowV2Model/unProve`, params,config).then(res => res.data); };//开票反审核
export const invoiceBillApi = params => { return axios.post(`${baseOms2}/m/s/invoicing/invoiceBill`, params,config).then(res => res); };//哆啦开票

export const invoiceExportApi = params => { return axios.post(`${baseOms2}/mt/s/SiteInvoiceV2Model/exportItem`, params,config).then(res => res.data); };//发票导出
export const companyEnumApi = params => { return axios.post(`${baseOms2}/mt/s/SiteInvoiceV2Model/companyEnum`, params,config).then(res => res.data); };//公司列表

export const getFeedbackListApi = params => { return axios.post(`${baseOms1}/m/s/invoicing/getFeedbackList`, params,config).then(res => res.data); };//反馈列表
export const getDepartListApi = params => { return axios.post(`${baseOms1}/m/s/invoicing/getDepartList`, params,config).then(res => res.data); };//部门列表
export const setDepartApi = params => { return axios.post(`${baseOms1}/m/s/invoicing/setDepart`, params,config).then(res => res.data); };//为问题反馈设置部门
export const exportFeedbackApi = params => { return axios.post(`${baseOms1}/m/s/invoicing/exportFeedback`, params,config).then(res => res.data); };//问题反馈导出


//哆啦接口
export const duolaDoInvoiceApi = params => { return axios.post(`${duola}/eis/sal/invoice/bill/submit`, params,config).then(res => res.data); };//提交开票请求（哆啦）
export const duolaTitleSearchApi = params => { return axios.get(`${duola}/eis/sal/company/search`, params,config).then(res => res.data); };//抬头信息模糊匹配（哆啦）

//促销活动
export const getPromotionApi = params => { return axios.post(`${baseOms2}/mt/s/CrmPromoteModel/getListPageQ`, params,config).then(res => res); };//获取促销列表
export const addPromotionApi = params => { return axios.post(`${baseOms2}/mt/s/CrmPromoteModel/add`, params,config).then(res => res.data); };//添加促销活动
export const delPromotionApi = params => { return axios.post(`${baseOms2}/mt/s/CrmPromoteModel/del`, params,config).then(res => res.data); };//删除促销活动
export const getPromotionInfoApi = params => { return axios.post(`${baseOms2}/mt/s/CrmPromoteModel/get`, params,config).then(res => res.data); };//查看促销活动
export const getOverPromotionApi = params => { return axios.post(`${baseOms2}/mt/s/CrmPromoteItemsModel/getOver`, params,config).then(res => res.data); };//查看起始数量
export const saveOverPromotionApi = params => { return axios.post(`${baseOms2}/mt/s/CrmPromoteItemsModel/saveOver`, params,config).then(res => res.data); };//保存起始数量
export const getItemPromotionApi = params => { return axios.post(`${baseOms2}/mt/s/CrmPromoteItemsModel/getListPageQ`, params,config).then(res => res.data); };//查看活动商品
export const getErpProApi = params => { return axios.post(`${baseOms2}/mt/s/ErpProductSkuModel/getListPageQ`, params,config).then(res => res.data); };//获取家具列表
export const getErpProGcrApi = params => { return axios.post(`${baseOms2}/mt/s/ErpProductSkuGcrModel/getListPageQ`, params,config).then(res => res.data); };//获取杂货列表
export const saveErpProApi = params => { return axios.post(`${baseOms2}/mt/s/CrmPromoteItemsModel/save`, params,config).then(res => res.data); };//保存活动商品
export const delErpProApi = params => { return axios.post(`${baseOms2}/mt/s/CrmPromoteItemsModel/del`, params,config).then(res => res.data); };//删除活动商品
export const addAllProApi = params => { return axios.post(`${baseOms2}/mt/s/CrmPromoteItemsModel/addAllSku`, params,config).then(res => res.data); };//添加全部家具商品
export const vipEnableApi = params => { return axios.post(`${baseOms2}/mt/s/CrmPromoteItemsModel/vipEnable`, params,config).then(res => res.data); };//是否参与活动

//销售日报
export const getSumReportApi = params => { return axios.post(`${baseOms2}/mt/s/SaleCusRcdModel/getSumReport`, params,config).then(res => res.data); };//销售日报列表
export const getShopEnumApi = params => { return axios.post(`${base}/m/s/common/getShopEnum`, params,config).then(res => res.data); };//门店列表

//供应商相关
export const getSupplierInfoListApi = params => { return axios.post(`${baseOms2}/mt/s/PurchaseSupplierModel/getListPageQ`, params,config).then(res => res.data); };//供应商信息列表
export const getSupplierInfoApi = params => { return axios.post(`${baseOms2}/mt/s/PurchaseSupplierModel/get`, params,config).then(res => res.data); };//供应商信息
export const addSupplierInfoApi = params => { return axios.post(`${baseOms2}/mt/s/PurchaseSupplierModel/add`, params,config).then(res => res.data); };//添加供应商信息
export const modSupplierInfoApi = params => { return axios.post(`${baseOms2}/mt/s/PurchaseSupplierModel/mod`, params,config).then(res => res.data); };//修改供应商信息
export const delSupplierInfoApi = params => { return axios.post(`${baseOms2}/mt/s/PurchaseSupplierModel/del`, params,config).then(res => res.data); };//删除供应商
export const getSupplierProInfoListApi = params => { return axios.post(`${baseOms2}/mt/s/SupplierProductModel/getListPageQ`, params,config).then(res => res.data); };//供应商商品信息列表
export const getSupplierProInfoApi = params => { return axios.post(`${baseOms2}/mt/s/SupplierProductModel/get`, params,config).then(res => res.data); };//供应商商品信息
export const addSupplierProInfoApi = params => { return axios.post(`${baseOms2}/mt/s/SupplierProductModel/add`, params,config).then(res => res.data); };//添加供应商商品
export const modSupplierProInfoApi = params => { return axios.post(`${baseOms2}/mt/s/SupplierProductModel/mod`, params,config).then(res => res.data); };//修改供应商商品信息
export const delSupplierProInfoApi = params => { return axios.post(`${baseOms2}/mt/s/SupplierProductModel/del`, params,config).then(res => res.data); };//删除供应商商品信息
export const enumSupplierApi = params => { return axios.post(`${baseOms2}/mt/s/PurchaseSupplierModel/enum`, params,config).then(res => res.data); };//供应商下拉框
export const enumPurTypeSupplierApi = params => { return axios.post(`${baseOms2}/mt/s/SysArchivesV2Model/enum?arId=25`, params,config).then(res => res.data); };//供应商下拉框

//产品二维码
export const spuListApi = params => { return axios.post(`${baseOms2}/mt/s/ItemSpuModel/getListPageQ`, params,config).then(res => res.data); };//SPU列表
export const modSpuPdfApi = params => { return axios.post(`${baseOms2}/mt/s/ItemSpuModel/mod`, params,config).then(res => res.data); };//修改SPU

export const uploadFileApi = params => { return axios.post(`${baseOms2}/upload`, params,importFile).then(res => res.data); };//上传文件
export const uploadApi = params => { return axios.post(`${base}/m/s/upload`, params,importFile).then(res => res.data); };//上传文件
import axios from 'axios';

import { Toast } from 'vant';
import { ElLoading } from 'element-plus';
import routerIndex from '../router/index'

axios.defaults.withCredentials = false;
// let loadingInstance;

console.log(routerIndex)

//添加一个请求拦截器
axios.interceptors.request.use(function (config)
{
    if(config.method=="get"){
        config.data = true;
    }
    let token = 'default';
    let user = JSON.parse(window.sessionStorage.getItem('access-user'));
    if (user)
        token = user.token;
    config.headers['Authorization'] = localStorage.getItem("erp.token");
    config.headers['AppSecret'] = 'fJRSN5GfXJ$vqv';
    config.headers['TenantId'] = '297';
    // loadingInstance = ElLoading.service({
	// 	target: '.main',
	// 	text: '加载中',
	// 	background: 'rgba(0,0,0,0.5)'
	// });
    return config;
}, function (error) {
    // Do something with request error
    console.info("error: ");
    console.info(error);
    return Promise.reject(error);
});

// 添加一个响应拦截器
axios.interceptors.response.use(function (response)
{
//   loadingInstance.close();
  if (response.data) {
        if(parseInt(response.data.error) == 100)
        {
            alert("登录超时，请重新登录");

            // router.push('/login');
            //road.$message.error(response.data.msg);
            // window.location.href="../../static/pages/login.html";

        }
        else if(!parseInt(response.data))
        {
            return response;
        }
        else if(parseInt(response.data.error) !=0)
        {
            alert("接口错误：<br>"+response.data.data);

        }
    }
    return response;
}, function (error) {
    // Do something with response error
    console.dir(error);
    //road.$message.error("服务器连接失败");
    return Promise.reject(error);
})

import store from '@/store'
import { el } from 'element-plus/es/locale'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/AboutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '首页',
    // meta: {gorouter: true},
    component: HomeView
  },
  {
    path: '/marked',
    name: 'marked',
    component: () => import(/* webpackChunkName: "about" */ '../views/Marked.vue')
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import(/* webpackChunkName: "about" */ '../views/Demo.vue')
  },
  {
    path: '/login',
    name: '登陆',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/index',
    name: '主页',
    // meta: {gorouter: true},
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/vip',
    name: '会员',
    component: () => import(/* webpackChunkName: "about" */ '../views/Vip.vue')
  },
  {
    path: '/iframe',
    name: 'iframe',
    // meta: {gorouter: true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/iframe.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach((to,from,next)=>{
//   if(to.meta.gorouter){
//     if(localStorage.getItem("erp.token")){
//       next()
//     }else{
//       next({
//         path: '/login',
//         query: {redirect: to.fullPath}
//       })
//     }
//   }else{
//     next()
//   }
// })

export default router

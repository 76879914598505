export const debounce = (fn:Function, delay:number) => {
    let timer:any = null
    return function(this:object){
        let args = arguments;
        if(timer){
            clearTimeout(timer)
        }
        timer = setTimeout(()=>{
            fn.apply(this,args)
        }, delay)
    }
}

import Sidebar from '@/components/sidebar.vue'; // @ is an alias to /src
import { computed,ref } from 'vue';
import Topbar from './components/topbar.vue';
import router from './router';
// let isFrist = computed(()=>{
//   return router.currentRoute.value.fullPath == '/';
// })
export default {
  components: {Sidebar,Topbar},
  computed: { 
    islogin():boolean{
      return router.currentRoute.value.fullPath.indexOf('/login')!==-1;
    },
    isFrist():boolean{
      return window.innerWidth>1024;
    }
  },
  data(){
    return{

    }
  }
}
